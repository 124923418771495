import React, { useEffect, useState } from 'react'
import { DataFaqItem, DataFaqThemeBlock } from '../../../templates/faq-page'
import Text from '../../Core/Text'
import ArrowCircle from '../../Icon/ArrowCircle'
import * as styles from './faqContent.module.scss'

interface FaqContentProps {
  data: DataFaqThemeBlock
  index: number
}

export default function FaqContent(props: FaqContentProps) {
  const { data, index } = props
  const [currentContent, setCurrentContent] = useState<DataFaqItem | null>(null)
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)

  const handleChangeContent = (index: number) => {
    setCurrentContent(data.questions[index])
    setCurrentQuestionIndex(index)
  }

  useEffect(() => {
    if (data.questions) {
      setCurrentContent(data.questions[0])
      setCurrentQuestionIndex(0)
    } else {
      setCurrentContent(null)
    }
  }, [index])

  return (
    <div className={styles.faqWrap}>
      {data.questions && (
        <div className={styles.faqQuestion}>
          <ul>
            {data.questions.map((d, index) => (
              <li
                key={`d-${index}`}
                onClick={() => handleChangeContent(index)}
                className={`${
                  currentQuestionIndex === index ? styles.active : ''
                }`}
              >
                <ArrowCircle />
                <Text tag="h2" as="bodyH2" className="font-normal">
                  {d.question}
                </Text>
              </li>
            ))}
          </ul>
        </div>
      )}
      <div className={styles.faqAnwser}>
        {currentContent && (
          <>
            <Text tag="p" as="h4" className={`${styles.answerTitle} font-bold`}>
              {currentContent.question}
            </Text>
            <Text
              tag="div"
              as="bodyH1"
              dangerouslySetInnerHTML={{ __html: currentContent.answer }}
            />
          </>
        )}
      </div>
    </div>
  )
}
